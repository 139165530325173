import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')
];

export const server_loads = [0,3,4,2];

export const dictionary = {
		"/(home)": [~7,[3]],
		"/admin/member": [~15,[4]],
		"/admin/model": [~16,[4]],
		"/(home)/ask": [~8,[3]],
		"/(home)/assistant": [~9,[3]],
		"/(home)/assistant/create": [~12,[3]],
		"/(home)/assistant/thread/[id]": [~13,[3]],
		"/(home)/assistant/[id]": [~10,[3]],
		"/(home)/assistant/[id]/edit": [~11,[3]],
		"/(error)/auth/already_registered": [5,[2]],
		"/(error)/auth/require_signin": [6,[2]],
		"/invitation": [~17],
		"/(home)/thread/[id]": [~14,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';